<template>
  <div
    class="wrap-auto-suggest-box"
    :class="appAutoCompleteLoading ? 'app-autocomplete-loading' : null"
  >
    <app-loading v-if="appAutoCompleteLoading" />
    <ul
      v-if="!appAutoCompleteLoading && autoCompleteData.length > 0"
      class="general-search mt-1"
    >
      <li
        v-for="(item, index) of autoCompleteData"
        :key="index"
        @click="onOptionClick(item)"
      >
        <div class="wrap-item-info-autocomplete">
          <b-avatar
            ref="previewEl"
            :src="$helpers.imageHelper(item.picture)"
            size="50px"
            rounded
          />
          {{ item.first_name }} {{ item.last_name }}
        </div>
      </li>
    </ul>
    <p
      v-if="!appAutoCompleteLoading && autoCompleteData.length === 0"
      class="text-center pt-1"
    >
      {{ $t('No data found') }}...
    </p>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'
import AppLoading from '@/@core/layouts/components/app-navbar/components/AppLoading.vue'
import { showErrorNotification } from '@/@core/comp-functions/ui/app'
import { useStoreUi } from '../store/useStore'
import { useProviderUi } from '../provider/useProvider'

export default {
  components: {
    BAvatar,
    AppLoading,
  },
  props: {
    searchCriteria: {
      type: String,
      default: '',
    },
    userSearchType: {
      type: String,
      default: 'STORE',
    },
  },
  data() {
    return {
      appAutoCompleteLoading: false,
      autoCompleteData: [],
    }
  },
  methods: {
    onOptionClick(item) {
      this.$emit('user-selected', item)
    },
    onSearch() {
      const { storeAutoComplete } = useStoreUi()
      const { providerAutoComplete } = useProviderUi()
      const apiCall = this.userSearchType === 'STORE' ? storeAutoComplete : providerAutoComplete
      this.appAutoCompleteLoading = true
      apiCall(this.searchCriteria)
        .then(({ data }) => {
          if (data.success) {
            this.autoCompleteData = data.data
          }
          this.appAutoCompleteLoading = false
        })
        .catch(error => {
          this.appAutoCompleteLoading = false
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap-auto-suggest-box {
  position: absolute;
  z-index: 999;
  background: #fff;
  width: 95%;
  box-shadow: 0px 5px 20px #eaecef;
  border-radius: 4px;

  .general-search {
    padding: 0;
  }

  li {
    padding: 2px 10px;
    border: none;
    list-style-type: none;
    cursor: pointer;
  }
}
</style>
